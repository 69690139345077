<template>
  <div class="d-flex flex-column flex-root">
    <div
        id="kt_login"
        class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #39576c">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/logo-itograss.svg" class="max-h-70px" alt="">
          </a>
        </div>
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="text-center">
            <div class="spinner spinner-primary spinner-lg d-inline"/><br/><br/>
            <span class="font-size-lg font-weight-bold">Servidor em manutenção, tentanto reconectar...</span>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>

import ApiService from '@/core/services/api.service'
import router from '@/router'

export default {
  name: 'Maintence',
  data: () => ({
  }),
  mounted () {
    const internval = setInterval(function () {
      ApiService.get('/ping').then(function (response) {
        clearInterval(internval)
        router.back()
      })
    }, 5000)
  },
}
</script>
